.App {
    text-align: center;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    min-height: 100vh;
    vertical-align: top;
    justify-content: flex-start;
    background-color: #f7fafc;
    flex-direction: column;
}

.header
{
    background-color: #2e323b;
    width: 100%;
    height: 70px;
}

.headerSpacer
{
    width: 100%;
    height: 25px;
}

.headerContent
{
    width: 100%;
    height:100%;
    max-width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.headerTitle
{
    color: white;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    width: 100%;
    max-width: 500px;
    border: 1px solid rgba(10, 10, 10, 0.1);
    padding: 10px;
    background-color: white;
    position: relative;

}

.shopTitle {
    text-align: left;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.value {
    font-weight: 600;
    font-size: 25px;
    margin: 10px;
}

.checkoutItem {
    font-size: 15px;
}

.confirmButton {
    background-color: #3d424e;
    color: #b1b3b8;
    padding: 10px;
    width: 200px;
    margin-top: 15px;
    font-weight: bold;
    font-size: 15px;
    border-radius: 7px;
    cursor: pointer;
    transition:all .15s ease-out;
    -moz-transition:all .15s ease-out;
    -webkit-transition:all .15s ease-out;
}

.confirmButton:hover {
    color:#ffffff;
}


.checkout {
}

.StripeElement {
    box-sizing: border-box;

    height: 40px;

    padding: 10px 12px;

    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;

    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

.spinner {
    width: 40px;
    height: 40px;

    position: absolute;

    left: 50%;
    top: 10px;
    transform: translate(-50%, 0);
}

.spinnerBg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(250, 250, 250, 0.6);

}

.double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #3d424e;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
    0%, 100% {
        -webkit-transform: scale(0.0)
    }
    50% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bounce {
    0%, 100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    }
    50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}